import React, { Suspense } from "react";
import "./App.css";

import { Canvas } from "react-three-fiber";

import Model from "./Litterbox";

function App() {
  return (
    <div className="App">
      <div className="title">
        <h1>The Litterbox</h1>
        <h4>Where everybody gives a shit</h4>
      </div>
      <Canvas colorManagement shadowMap style={{ height: 200, width: 400 }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Suspense fallback={null}>
          <Model position={[0, 0, -50]} receiveShadow castShadow />
        </Suspense>
        <mesh
          rotation={[-Math.PI / 2, 0, 0]}
          position={[0, -0.5, 0]}
          receiveShadow
        >
          <planeBufferGeometry attach="geometry" args={[100, 100]} />
          <shadowMaterial attach="material" transparent opacity={0.4} />
        </mesh>
      </Canvas>
    </div>
  );
}

export default App;
